/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

function SEO({ lang, meta, placeName, metaTitle, metaDescription }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const _metaDescription = metaDescription || site.siteMetadata.description
  const _metaTitle =
    metaTitle ||
    `สั่งพวงหรีดออนไลน์ พวงหรีดดอกไม้สด ราคาถูกจาก ร้านพวงหรีด ใกล้วัด มี 50 สาขา ใกล้วัดพระศรี, วัดธาตุทอง และอื่นๆทั่วประเทศ จัดส่งก่อนเริ่มพิธี มีบริการถ่ายรูปยืนยันก่อน-หลังส่ง`
  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={_metaTitle}
      titleTemplate={_metaTitle}
      meta={[
        {
          name: `description`,
          content: _metaDescription
        },
        {
          name: `keywords`,
          content:
            'พวงหรีด, พวงหรีดดอกไม้สด, พวงหรีดพัดลม, พวงหรีดเดลิเวอร์รี่, พวงหรีดด่วน, พวงหรีดหน้าวัด, พวงหรีดออนไลน์, ร้านพวงหรีด, พวงหรีดราคาถูก'
        },
        {
          property: `og:title`,
          content: _metaTitle
        },
        {
          property: `og:description`,
          content: _metaDescription
        },
        {
          property: `og:url`,
          content: 'https://www.tanjai.co/'
        },
        {
          property: `og:site_name`,
          content: _metaTitle
        },
        {
          property: `og:image`,
          content:
            'https://tanjai.s3-ap-southeast-1.amazonaws.com/near-wat/static/nearwat_meta_description_1200x630.png'
        },
        {
          property: `og:description`,
          content: _metaDescription
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          property: `og:image`,
          content:
            'https://tanjai.s3-ap-southeast-1.amazonaws.com/near-wat/static/nearwat_meta_description_1200x630.png'
        },
        {
          name: `twitter:card`,
          content: `summary`
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author
        },
        {
          name: `twitter:title`,
          content: _metaTitle
        },
        {
          name: `twitter:description`,
          content: _metaDescription
        },
        {
          name: `twitter:image`,
          content:
            'https://tanjai.s3-ap-southeast-1.amazonaws.com/near-wat/static/nearwat_meta_description_1200x630.png'
        }
      ].concat(meta)}
    >
      <script type="application/ld+json">
        {`{
"@context": "http://schema.org",
"@type": "LocalBusiness",
"name": "Nearwat",
"alternatename" : "ใกล้วัด",
"address": {
"type": "PostalAddress",
"streetAddress": "Sukhumvit Rd.",
"addressLocality": "101 True Digital Park 5th Floor",
"addressRegion": "Bang Chak Prakanong Bangkok",
"postalCode": "10260",
  "addressCountry": "Thailand"
},
"telephone" : "+66868605345",
"url" : "https://nearwat.com/",
"email": "tanjai.bkk@gmail.com",
"logo": "https://tanjai.s3-ap-southeast-1.amazonaws.com/near-wat/static/logo_1.png",
"image": "https://tanjai.s3-ap-southeast-1.amazonaws.com/near-wat/static/nearwat_meta_description_1200x630.png",
"priceRange": "$$$",
"description": "สั่งพวงหรีดออนไลน์ พวงหรีดดอกไม้สด ราคาถูกจาก ร้านพวงหรีด ใกล้วัด มี 50 สาขา ใกล้วัดพระศรี, วัดธาตุทอง และอื่นๆทั่วประเทศ จัดส่งก่อนเริ่มพิธี มีบริการถ่ายรูปยืนยันก่อน-หลังส่ง",
"openingHours": ["Mo-Su 09:00-22:00"],
"hasMap": "https://www.google.com/maps/place/True+Digital+Park/@13.6756022,100.6046477,15z/data=!4m5!3m4!1s0x30e29ed269181bb1:0x60c3178ba983c76!8m2!3d13.6851249!4d100.6110206",
"geo": {
	"@type": "GeoCoordinates",
	"latitude": "13.6756022",
	"longitude": "100.6046477"
},
"sameAs": [ 
]
}`}
      </script>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  placeName: ''
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  placeName: PropTypes.string
}

export default SEO
